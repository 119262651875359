<template>
  <a-stepper :slots="5" class="h-screen flex flex-col overflow-y-auto stepper-padding">

<!--    <template #header>-->
<!--      <div class="px-4 pt-4 fixed top-0 bg-white max-w-5xl mx-auto inset-x-0 lg:top-auto">-->
<!--        <a-logo-switch @click="redirectHome" class="p-2 h-12 cursor-pointer" />-->
<!--      </div>-->
<!--    </template>-->

    <template #step-1>
      <Step01 />
    </template>

    <template #step-2>
      <Step02 />
    </template>

    <template #step-3="{ goNext }">
      <Step03 @start="goNext"/>
    </template>

    <template #step-4>
      <Step1
          v-model:name="user.name"
          v-model:email="user.email"
          v-model:password="user.password"
          v-model:invitation_code="user.invitation_code"
          v-model:terms="user.privacy_agreement.terms_and_privacy"
          v-model:notifications="user.privacy_agreement.commercial_communications"
          v-model:validEmail="validEmail"
          v-model:ig_username="user.ig_username"
          v-model:emailError="errors.emailError"
          v-model:igUsernameTaken="errors.igUsernameTaken"
          :invitation_code_verify="invitation_code_verify"
          @should-disable-next="shouldDisableNext = $event"
          @should-disable-next-code="shouldDisableNextCode = $event"
          class="flex-grow flex justify-center items-center" />
    </template>

    <template #step-5>
      <Step5
        v-model:phone="user.phone"
        v-model:address="user.address.line"
        v-model:city="user.address.city"
        v-model:state="user.address.state"
        v-model:zip_code="user.address.zip_code"/>
    </template>

    <template #step-6>
      <step-4 v-if="!igExist" :foodie="registered" @verify-ig="igVerify(user.ig_username)" :reloading="loadings.igVerifying" class="flex-grow flex justify-center items-center" />
      <step-3 v-else :foodie="registered" class="flex-grow flex justify-center items-center" />
    </template>

    <template #footer="{ goStep, index, goNext, goPrev }">
      <div v-if="index <= 3" class="flex absolute bottom-12 justify-between items-center p-4 w-full">
        <button
          :disabled="index === 1"
          @click="goPrev"
          v-if="index < 5"
          class="bg-white focus:outline-none flex justify-center text-black p-2 rounded-full uppercase disabled:bg-onboarding_light_gray"
        >
          <button class="rounded-full h-10 w-10">
            <i class="icon-arrowLeft"></i>
          </button>
        </button>

        <span class="flex space-x-1">
            <span v-for="n in 3" :key="n" :class="['inline-block transition transition-all h-3 rounded-full', {
              'w-6 bg-SFRed': index === n,
              'w-3 bg-onboarding_light_gray': index !== n
            }]" />
        </span>
        <button
          @click="goNext"
          :disabled="index >= 3"
          v-if="index <= 2"
          class="bg-SFRed focus:outline-none flex justify-center text-white p-2 rounded-full uppercase"
        >
          <button class="rounded-full h-10 w-10 text-white">
            <i class="icon-arrowRight"></i>
          </button>
        </button>
        <div v-else class="w-14"></div>
      </div>

      <div v-else>
        <button
          :disabled="index === 4"
          @click="goPrev"
          v-if="index > 4"
          class="p-2 bg-white absolute bottom-4 left-4 focus:outline-none flex justify-center text-black rounded-full disabled:bg-onboarding_light_gray"
        >
          <button class="rounded-full h-10 w-10">
            <i class="icon-arrowLeft"></i>
          </button>
        </button>

        <button
          @click="register(goStep, goNext, index)"
          :disabled="loadings.store || shouldDisableNext || shouldDisableNextCode || (user.phone.length < 9 && index === 5) || user.password < 6 || !user.privacy_agreement.terms_and_privacy || !user.email || !user.ig_username || (index === 5 && !user.address.line) || (index === 5 && !user.address.city) || (index === 5 && !user.address.state) || (index === 5 && !user.address.zip_code)"
          v-if="index > 3"
          class="p-2 absolute bottom-4 right-4 bg-white text-black focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray"
        >
          <button class="rounded-full h-10 w-10">
            <a-loader v-if="loadings.store" class="h-6 w-6 block mx-auto"/>
            <i v-else class="icon-arrowRight" />
          </button>
        </button>
      </div>
    </template>
  </a-stepper>

  <a-alert
      title="¡Un momento por favor!"
      :show="invitation_code_required"
      @cancel="invitation_code_required = false">
    <h1>No puedes registrarte sin un código de invitación</h1>
  </a-alert>
</template>

<script>
import Step01 from '../step-01'
import Step02 from '../step-02'
import Step03 from '../step-03'
import Step1 from './step-1'
// import Step2 from './step-2'
import Step3 from './step-3'
import Step4 from './step-4'
import Step5 from './step-5'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Step01,
    Step02,
    Step03,
    Step1,
    Step3,
    Step4,
    Step5
  },
  data: () => ({
    user: {
      name: '',
      email: '',
      password: '',
      latitude: '',
      longitude: '',
      ig_username: '',
      privacy_agreement: {
        commercial_communications: false,
        terms_and_privacy: false
      },
      invitation_code: '',
      phone: '',
      address: {
        line: '',
        city: '',
        state: 'madrid',
        zip_code: ''
      }
    },
    invitation_code_required: false,
    invitation_code_verify: false,
    shouldDisableNext: false,
    shouldDisableNextCode: false,
    validEmail: false,
    registered: null,
    loadings: {
      facebook: false,
      store: false,
      igVerifying: false,
      email: false
    },
    credentials: {
      body: []
    },
    errors: {
      igUsernameTaken: false,
      emailError: false
    },
    igExist: null
  }),
  computed: {
    ...mapState({
      facebookSession: ({ session }) => session.temporalFacebookLogin
    })
  },
  methods: {
    ...mapActions({
      login: 'session/login'
    }),
    isNextDisabled (index) {
      switch (index) {
        case 4: return !Object.entries(this.user).every(([attribute, value]) => {
          if (['latitude', 'longitude'].includes(attribute)) {
            return true
          }
          if (attribute === 'notifications') {
            return true
          }
          return value
        })
        default: return false
      }
    },
    // handleStart (goStep) {
    //   const registered = localStorage.getItem('foodie_registered')
    //   if (registered) {
    //     this.registered = JSON.parse(registered)
    //
    //     return goStep(5)
    //   }
    //
    //   return goStep(4)
    // },
    //
    register (goStep, goNext, index) {
      switch (index) {
        case 4: {
          this.$repository.session.emailConfirm({ email: this.user.email })
            .then(response => {
              if (response.data._exists) {
                this.validEmail = true
              } else {
                this.validEmail = false
                goNext()
              }
            })
            .catch(() => {
              const error = Object.keys(this.$store.state.errors)
              if (error[0] === 'email') {
                this.errors.emailError = true
              }
              this.loadings.email = !this.loadings.email
            })
            .finally(() => {
              // this.loadings.store = !this.loadings.store
              this.loadings.email = !this.loadings.email
            })
          break
        }
        case 5: {
          this.loadings.store = !this.loadings.store
          this.$repository.session.emailConfirm({ email: this.user.email })
            .then(response => {
              if (response.data._exists) {
                this.validEmail = true
              } else {
                this.validEmail = false

                let emptyAddress

                if (!this.user.address.line || !this.user.address.city || !this.user.address.zip_code || !this.user.address.state) {
                  emptyAddress = null
                } else {
                  emptyAddress = { ...this.user.address }
                }

                console.log({ ...this.user, address: emptyAddress })
                this.$repository.foodies
                  .store({ ...this.user, address: emptyAddress })
                  .then(({ data }) => {
                    this.registered = { ...data }
                    // this.igVerify(this.user.ig_username)
                  })
                  .catch(() => {
                    const error = Object.keys(this.$store.state.errors)
                    // this.errors.registerFoodie = true
                    if (error[0] === 'ig_username') {
                      this.errors.igUsernameTaken = true
                    }
                    if (error[0] === 'email') {
                      this.errors.emailError = true
                    }
                  })
                  .finally(() => {
                    this.credentials.body = {
                      email: this.user.email,
                      password: this.user.password
                    }
                    this.login(this.credentials.body)
                      .catch(error => (console.log(error)))
                      .finally(() => {
                        this.loadings.store = !this.loadings.store
                        this.$router.replace({ name: 'Explore' })
                      })
                  })
              }
            })
            .catch(() => {
              const error = Object.keys(this.$store.state.errors)
              if (error[0] === 'email') {
                this.errors.emailError = true
              }
              this.loadings.email = !this.loadings.email
            })
            .finally(() => {
              // this.loadings.store = !this.loadings.store
              this.loadings.email = !this.loadings.email
            })
          break
        }
        default: {
          goNext()
          break
        }
      }
    },
    redirectHome () {
      this.$router.push({ path: '/login' })
    },
    searchEmail (email) {
      this.searching = !this.searching
      this.$repository.session
        .emailConfirm({ email })
        .then(({ data }) => {
          this.emailExists = data._exists
          this.$emit('should-disable-next', data._exists)
        })
        .catch(() => (this.emailExists = null))
    },
    igVerify (igUsername) {
      this.loadings.igVerifying = !this.loadings.igVerifying
      this.$repository.session
        .igUsername({ ig_username: igUsername })
        .then(({ data }) => {
          this.igExist = data._exists_on_instagram
        })
        .finally(() => (this.loadings.igVerifying = !this.loadings.igVerifying))
    }
  },
  mounted () {
    window.scrollTo(0, 100)
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      this.user.latitude = coords.latitude
      this.user.longitude = coords.longitude
    }, err => {
      console.log('error =>', err)
    })

    if (this.$route.query.invitation_code) {
      this.user.invitation_code = this.$route.query.invitation_code
      this.invitation_code_verify = !this.invitation_code_verify
    }
  }
}
</script>
