<template>
  <div class="flex flex-col justify-center h-full space-y-8 px-4">
    <div>
      <h1 class="text-xl">
        Debes tener una cuenta de creador para poder continuar.
      </h1>
    </div>
    <div class="justify-self-start">
      <h1 class="text-lg text-left">
        Entra en Instagram:
      </h1>
    </div>
    <div class="text-left">
      <p>1.<span class="w-2"></span> Accede a la configuración de tu cuenta.</p>
      <p>2. Ingresa a la pestaña “Cuenta”.</p>
      <p>3. Desplázate hacia abajo hasta la opción “Cambiar tipo de cuenta”.</p>
      <p>4. Elegir “Cambiar a cuenta de creador”.</p>
      <p>5. Vuelve a Solofoodies.com e inicia sesión.</p>
    </div>
    <div>
      <div v-if="reloading">
        <a-loader class="h-4 w-4"></a-loader>
      </div>
      <p v-else class="cursor-pointer underline text-xl" @click="$emit('verify-ig')">Actualizar verificación</p>
    </div>
    <div>
      <p class="text-sm">info@solofoodies.com</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-4',
  emits: ['verify-ig'],
  props: {
    reloading: { required: false, type: Boolean }
  }
}
</script>

<style scoped>

</style>
