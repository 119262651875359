<template>
  <div class="space-y-8 flex flex-col px-4">
    <p class="mx-24 font-light">Completa tu perfil y ayudanos a conocerte mejor (:</p>

    <div class="space-y-4">
      <a-picture :src="picture" class="flex justify-center items-center shadow-md w-32 h-32 rounded-full bg-gray-300" />
      <p class="font-light">Sube tu foto de perfil</p>
    </div>

    <div class="space-y-4 flex flex-col w-full px-4">
      <input
        type="text"
        placeholder="Nombre"
        :value="first_name"
        @input="$emit('update:first_name', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full">

      <input
        type="text"
        placeholder="Apellido"
        :value="last_name"
        @input="$emit('update:last_name', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full">
    </div>

  </div>
</template>

<script>
export default {
  props: {
    first_name: { required: false, type: String, default: '' },
    last_name: { required: false, type: String, default: '' },
    picture: { required: false, type: String, default: '' }
  }
}
</script>
