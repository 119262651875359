<template>
  <div class="space-y-8 flex flex-col text-gray_light pb-16">
<!--    <p class="mx-24 font-light text-center">¡Ya casi estamos!</p>-->
    <Logo class="self-center text-left mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>

    <a href="/login" class="mt-6 underline">Ya tengo cuenta</a>
    <p class="mx-16 font-light text-center lg:24">Agrega tu correo electrónico y una contraseña para entrar en Solofoodies</p>

    <div class="flex justify-center bg-cover items-center">
      <i class="icon-lock text-2xl"></i>
    </div>

    <div class="space-y-4 flex flex-col w-full px-4 text-">
      <input
        type="email"
        :value="email"
        placeholder="Correo electrónico *"
        @input="$emit('update:email', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full text-onboarding_gray focus:outline-none"
        :class="validEmail || emailError ? 'border-custom-red' : ''"
      >
      <div v-if="validEmail" class="text-red text-left">
        Este correo ya ha sido tomado
      </div>

      <div v-if="emailError" class="text-red text-left">
        El correo ingresado es incorrecto
      </div>

      <div class="flex items-center border border-gray-300 custom-rounded-border bg-white text-onboarding_gray">
        <input
          ref="password1"
          type="password"
          :value="password"
          placeholder="Contraseña *"
          @input="$emit('update:password', $event.target.value)"
          class="flex-grow border rounded-md px-4 py-2 w-full input-cancel-border focus:outline-none">
        <i @click="revealPassword" class="flex-none icon-eye px-2 text-xl font-bold"></i>
      </div>
      <div
        class="flex items-center border border-gray-300 custom-rounded-border bg-white text-onboarding_gray"
        :class="`${password_confirm === password && password.length > 0  ? 'border-custom-green' : password_confirm !== password && password_confirm && password ? 'border-custom-red' : 'border-gray-300' }`">
        <input
          ref="password2"
          type="password"
          :value="password_confirm"
          placeholder="Confirmar contraseña *"
          @input="$emit('update:password_confirm', $event.target.value)"
          class="flex border rounded-md px-4 py-2 w-full input-cancel-border focus:outline-none">
        <i @click="revealPassword2" class="flex-none icon-eye px-2 text-xl font-bold"></i>
      </div>
      <div v-if="password.length < 6 && password" class="text-red text-left">
        La contraseña debe tener al menos 6 caracteres
      </div>
      <div v-if="password_confirm !== password && password_confirm && password" class="text-red text-left">
        Las contraseñas deben ser iguales
      </div>
      <div class="text-sm">
        Solofoodies recoge tus datos para gestionar el alta dentro del Servicio. Si aceptas, te
        enviaremos comunicaciones comerciales sobre nuestros servicios, novedades y
        actualizaciones, a los que siempre te podrás oponer. Puedes ejercer tus derechos de
        acceso, rectificación, supresión y oposición, entre otros, según indica nuestra Politica de
        Privacidad.
      </div>
      <div class="text-center custom-max-w mx-auto">
        <div class="space-x-2">
          <input type="checkbox" id="comunications" @change="$emit('update:notifications', $event.target.checked)">
          <label for="comunications">
            <!--            Aceptar los <router-link class="text-primary">términos y condiciones</router-link>-->
            Acepto que Solofoodies me envíe comunicaciones comerciales
          </label>
        </div>
      </div>
      <div class="text-center custom-max-w mx-auto">
        <div class="space-x-2">
          <input type="checkbox" id="terms" @change="$emit('update:terms', $event.target.checked)">
          <label for="terms">
            <!--            Aceptar los <router-link class="text-primary">términos y condiciones</router-link>-->
            Acepto las <a :href="'/terms'" class="inline-block underline" target="_blank">Condiciones de Uso</a> y la <a :href="'/politics'" class="inline-block underline" target="_blank">Pol. de Privacidad</a>
          </label>
        </div>
      </div>
      <div v-if="confirmation" class="text-red text-left">
        Por favor complete todos los campos
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white'

export default {
  components: {
    Logo
  },
  props: {
    email: { required: false, type: String, default: '' },
    password: { required: false, type: String, default: '' },
    password_confirm: { required: false, type: String, default: '' },
    validEmail: { required: false, type: Boolean, default: false },
    confirmation: { required: false, type: Boolean, default: false },
    emailError: { required: false, type: Boolean, default: false }
  },
  methods: {
    revealPassword () {
      const password = this.$refs.password1
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    },
    revealPassword2 () {
      const password = this.$refs.password2
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    }
  }
}
</script>

<style>
.border-custom-red {
  border: 1px solid #FE4242 !important;
}
.border-custom-green {
  border: 1px solid #28c91b !important;
}
.custom-max-w{
  max-width: 300px;
}
@media screen and (min-width: 1000px) {
  .custom-max-w{
    max-width: 100%
  }
}
</style>
