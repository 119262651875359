<template>
  <div class="flex-grow flex flex-col justify-center items-center p-6 relative pb-8 bg-onboarding_gray text-white">
    <Logo class="self-start text-left mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>
    <div class="flex flex-col flex-grow justify-center gap-4">
      <div class="text-2xl leading-8 text-black font-thin text-left text-white">
        <p class="font-normal">Descubre</p>
        <p class="mt-2">y deja que te</p>
        <p class="mt-2 text-SFRed">descubran</p>
      </div>
      <h4 class="text-left text-xl font-thin">
        La manera más fácil de conectar con restaurantes, ser parte de aperturas y experiencias
      </h4>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white'
export default {
  components: {
    Logo
  }
}
</script>
