<template>
  <div class="space-y-12 flex flex-col text-gray_light pb-16">
    <Logo class="self-center text-left mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>

    <a href="/login" class="mt-6 underline">Ya tengo cuenta</a>
    <!--    <div v-if="latitude || longitude" >-->
<!--      <p class="mx-24 font-light">{{ latitude + '/' + longitude }}</p>-->
<!--    </div>-->
<!--    <p v-if="!latitude || !longitude" class="mx-24 font-light">Es necesario activar la geolocalizacion para mejorar la experiencia de usuario</p>-->
    <div class="space-y-3 w-full">
      <p class="mx-4 font-light">Añade los datos de tu restaurante</p>

      <div class="flex flex-col w-full px-4 text-black">
        <input
          type="text"
          placeholder="Dirección *"
          :value="address"
          @input="$emit('update:address', $event.target.value)"
          class="border rounded-md px-4 py-2 w-full">
      </div>
      <div class="flex w-full px-4 text-black">
        <input
          type="text"
          placeholder="Ciudad"
          :value="city"
          @input="$emit('update:city', $event.target.value)"
          class="border rounded-md px-4 py-2 mr-2 flex-1">
<!--        <input-->
<!--          type="text"-->
<!--          disabled-->
<!--          placeholder="Estado/Provincia/Región"-->
<!--          :value="state"-->
<!--          @input="$emit('update:state', $event.target.value)"-->
<!--          class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">-->
        <select id="companionSelectorSS" class="flex-1 border ml-2 p-1 rounded px-2" v-model="cstate" @change="this.$emit('update:state', $event.target.value)">
          <option value="madrid">Madrid</option>
          <option value="barcelona">Barcelona</option>
          <option value="sevilla">Sevilla</option>
          <option value="bilbao">Bilbao</option>
          <option value="valencia">Valencia</option>
          <option value="málaga">Málaga</option>
        </select>
      </div>
      <div class="flex w-full px-4 text-black">
        <input
          type="number"
          placeholder="Código postal"
          :value="zip_code"
          @input="$emit('update:zip_code', $event.target.value)"
          class="border rounded-md px-4 py-2 mr-2 flex-1">
        <input
          disabled
          type="text"
          value="España"
          class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">
      </div>
    </div>
    <div v-if="confirmation" class="text-red text-left">
      Por favor Agregue la dirección
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white'
// TODO ACOMODAR EL LOADER PERPETUO CUANDO NO SE HA INGRESADO LA DIRECCION
export default {
  components: {
    Logo
  },
  props: {
    // latitude: { required: false, type: Number },
    // longitude: { required: false, type: Number },
    address: { required: false, type: String, default: '' },
    city: { required: false, type: String, default: '' },
    state: { required: false, type: String, default: '' },
    zip_code: { required: false, type: String, default: '' },
    confirmation: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      cstate: ''
    }
  },
  methods: {
    testing () {
      this.$emit('update:state', this.state)
    }
  },
  mounted () {
    this.cstate = this.state
  }
}
</script>
