<template>
  <div class="space-y-10 flex flex-col text-gray_light">
    <Logo class="self-center text-left mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>
    <div class="space-y-4">
      <p class="mx-24 font-light">Necesitamos tu información para reservas y para participar en colaboraciones por delivery</p>

      <div class="px-4">
        <div class="overflow-hidden rounded-md w-full flex justify-between items-center px-2 bg-white">
          <label for="phone" class="text-onboarding_light_gray flex-none font-bold py-3 items-center">+34</label>
          <Mask
            id="phone"
            v-model="newPhone"
            class="inline-block text-lg"
            style="width: 100% !important;"
            placeholder="Teléfono para reserva *"
            @change="phoneFormatter($event)"
            @keypress.delete="newPhone = ''"
            @focus="phoneAux = ''"
            mask="999999999"/>
        </div>
      </div>

<!--      <p class="mx-12 font-light">Si quieres participar en colaboraciones de delivery, rellena tu dirección</p>-->
      <div class="mt-2 flex flex-col w-full px-4 text-black">
        <input
          type="text"
          placeholder="Dirección *"
          :value="address"
          @input="$emit('update:address', $event.target.value)"
          class="border rounded-md px-4 py-2 w-full">
      </div>
      <div class="flex w-full px-4 text-black">
        <input
          type="text"
          placeholder="Ciudad"
          :value="city"
          @input="$emit('update:city', $event.target.value)"
          class="border rounded-md px-4 py-2 mr-2 flex-1">
<!--        <input-->
<!--          type="text"-->
<!--          disabled-->
<!--          value="Madrid"-->
<!--          placeholder="Estado/Provincia/Región"-->
<!--          @input="$emit('update:state', $event.target.value)"-->
<!--          class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">-->
        <select id="companionSelectorSS" class="flex-1 border ml-2 p-1 rounded px-2" v-model="cstate" @change="$emit('update:state', $event.target.value)">
          <option value="madrid">Madrid</option>
          <option value="barcelona">Barcelona</option>
          <option value="sevilla">Sevilla</option>
          <option value="bilbao">Bilbao</option>
          <option value="valencia">Valencia</option>
          <option value="málaga">Málaga</option>
        </select>
      </div>
      <div class="flex w-full px-4 text-black">
        <input
          type="number"
          :value="zip_code"
          placeholder="Código postal"
          @input="$emit('update:zip_code', $event.target.value)"
          class="border rounded-md px-4 py-2 mr-2 flex-1">
        <input
          disabled
          type="text"
          value="España"
          class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white'
import Mask from 'primevue/inputmask'
// TODO ACOMODAR EL LOADER PERPETUO CUANDO NO SE HA INGRESADO LA DIRECCION
export default {
  name: 'step-5',
  components: {
    Logo,
    Mask
  },
  props: {
    phone: { required: false, type: String, default: '' },
    address: { required: false, type: String, default: '' },
    city: { required: false, type: String, default: '' },
    state: { required: false, type: String, default: '' },
    zip_code: { required: false, type: String, default: '' }
  },
  emits: ['update:phone', 'update:address', 'update:city', 'update:state', 'update:zip_code'],
  data: () => ({
    newPhone: '',
    cstate: ''
  }),
  methods: {
    phoneFormatter () {
      this.phoneAux = this.newPhone.replace(/\D/g, '')
      // console.log(this.newPhone)
      this.$emit('update:phone', this.phoneAux)
    }
  },
  watch: {
    newPhone (value) {
      this.phoneAux = this.newPhone.replace(/\D/g, '')
      this.$emit('update:phone', this.phoneAux)
    }
  },
  mounted () {
    this.newPhone = this.phone
    this.cstate = this.state
  }
}
</script>
<style scoped>
#phone{
  height: 48px !important;
  border: none;
  border-radius: 0 !important;
  padding: 0 2px;
  line-height: 48px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: left !important;
  font-size: 14px !important;
}
#phone:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-inputtext{
  background-color: #fff !important;
}
</style>
