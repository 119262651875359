<template>
  <keep-alive>
    <component :is="component" />
  </keep-alive>
</template>

<script>
import FoodieRegister from './register/foodie/Register'
import RestaurantRegister from './register/restaurant/native/Register'
import { mapState } from 'vuex'
export default {
  components: {
    FoodieRegister,
    RestaurantRegister
  },
  data: () => ({
    userType: null
  }),
  computed: {
    ...mapState({
      facebookSession: ({ session }) => session.temporalFacebookLogin
    }),
    component () {
      if (this.$route.name === 'register.native') {
        return `${this.$route.params.usertype === '1' ? 'foodie' : 'restaurant'}-register`
      } else {
        return `${this.userType === 1 ? 'foodie' : 'restaurant'}-register`
      }
    }
  },
  mounted () {
    this.userType = this.facebookSession?.api_user_type
  }
}
</script>
