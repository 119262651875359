<template>
  <div class="flex flex-col space-y-8 px-4">
    <div>
      <h1 class="text-xl">
        Hemos enviado un correo de verificación a:
      </h1>
      <div class="w-64">
      </div>
      <b class="text-xl text-primary">{{ foodie.foodie.email }}</b>
    </div>
    <div>
      <h1 class="text-lg px-16">
        Revisa tu buzón y sigue los pasos para verificar tu cuenta.
      </h1>
    </div>
    <div>
      <b>¿No has recibido el correo?</b>
      <p class="text-lg px-16">Revisa tu bandeja de correo no deseado</p>
    </div>
    <div>
      <p class="cursor-pointer underline text-xl">Volver a enviar correo</p>
    </div>
    <div>
      <p class="text-sm">¡Te ayudamos!, escríbenos a info@solofoodies.com</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    foodie: { required: true, type: Object }
  },
  data () {
    return {
      connected: 'undefined',
      registered: null,
      authorization: '',
      errorMessage: '',
      FBButton: true,
      igusernameError: false,
      igpagesList: [],
      errors: {
        registerFoodie: false,
        registerFoodieMessage: '',
        FBError: false,
        FBErrorMessage: ''
      }
    }
  },
  methods: {
    ...mapActions({
      facebookConnect: 'session/facebookConnect',
      configure: 'session/configure'
    })
  }
}
</script>
