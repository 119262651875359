<template>
  <a-stepper :slots="5" class="h-screen flex flex-col overflow-auto stepper-padding">

    <template #header>
      <div class="p-2 fixed top-0 inset-x-0 lg:top-auto">
        <a-logo-switch class="p-2 h-12" />
      </div>
    </template>

    <template #step-1>
      <step-01 />
    </template>

    <template #step-2>
      <step-02 />
    </template>

    <template #step-3="{ goNext }">
      <step-03 @start="goNext" />
    </template>

    <template #step-4>
      <step-1
        v-model:first_name="user.first_name"
        v-model:last_name="user.last_name"
        :picture="user.picture"
        class="flex-grow flex justify-center items-center" />
    </template>

    <template #step-5>
      <step-2 :facebook-user="user" class="flex-grow flex justify-center items-center" />
    </template>

    <template #footer="{ goStep, index, goNext }">
      <div class="fixed bottom-0 inset-x-0 lg:bottom-auto">
        <div class="flex justify-end p-4" v-if="index >= 4">
          <button
            :disabled="loadings.facebook || loadings.store"
            @click="register(goStep, index)"
            class="bg-primary focus:outline-none flex justify-center text-white w-full p-3 rounded-full uppercase disabled:bg-gray">
            <a-loader v-if="loadings.facebook || loadings.store" color="text-white" class="h-6 w-6" />
            <template v-else>
              {{ index !== 5 ? 'CONTINUAR' : 'FINALIZAR' }}
            </template>
          </button>
        </div>
        <div class="flex justify-between p-6" v-else>
          <span class="flex space-x-1">
            <span v-for="n in 3" :key="n" :class="['inline-block transition transition-all h-3 rounded-full', {
              'w-6 bg-primary': index === n,
              'w-3 bg-gray-200': index !== n
            }]" />
          </span>
          <button class="text-primary font-medium" @click="goNext" v-if="index < 3">
            Next
          </button>
        </div>
      </div>
    </template>
  </a-stepper>
</template>

<script>
import Step01 from '../step-01'
import Step02 from '../step-02'
import Step03 from '../step-03'
import Step1 from './step-1'
import Step2 from './step-2'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    Step01,
    Step02,
    Step03,
    Step1,
    Step2
  },
  data: () => ({
    isRegister: false,
    user: {
      first_name: '',
      last_name: '',
      picture: null,
      name: '',
      email: ''
    },
    loadings: {
      facebook: false,
      store: false
    }
  }),
  computed: {
    ...mapState({
      facebookSession: ({ session }) => session.temporalFacebookLogin
    })
  },
  methods: {
    ...mapActions({
      facebookLogin: 'session/facebookLogin'
    }),
    register (goStep, index) {
      if (index === 4) {
        goStep(5)
      } else {
        const { accessToken, userID, api_user_type: apiUserType } = this.facebookSession
        this.loadings.store = !this.loadings.store
        this.facebookLogin({
          access_token: accessToken,
          fb_user_id: `${userID}`,
          user_type: apiUserType
        }).then(() => this.$router.push({ name: 'Explore' }))
          .finally(() => (this.loadings.store = !this.loadings.store))
      }
    }
  },
  mounted () {
    this.isRegister = this.$route.name === 'register.native'
    if (this.$route.name === 'register') {
      this.loadings.facebook = !this.loadings.facebook
      this.$facebook.api
        .get(() => '/me', {
          fields: 'email,name,first_name,last_name,picture.width(130){url}'
        })
        .then(data => {
          this.user = {
            name: data.name,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            picture: data.picture.data.url
          }
        })
        .finally(() => (this.loadings.facebook = !this.loadings.facebook))
    }
  }
}
</script>
