<template>
  <div class="flex-grow flex flex-col p-6 relative pb-8 bg-onboarding_gray text-white">
    <Logo class="self-start text-left mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>
    <div class="flex flex-col flex-grow justify-center gap-4">
      <div class="text-2xl leading-8 text-black font-thin text-left text-white">
        <p class="font-normal">Crea <span class="text-SFRed font-semibold"> colaboraciones</span></p>
        <p class="mt-2">sin complicaciones</p>
      </div>
      <h4 class="text-left text-xl font-thin">
        Sabemos lo valioso que es tu tiempo, déjanos ayudarte a optimizarlo
      </h4>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white'
export default {
  components: {
    Logo
  }
}
</script>
