<template>
  <div class="space-y-8 flex flex-col text-gray_light">
    <Logo class="self-center text-left mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>

    <a href="/login" class="mt-6 underline">Ya tengo cuenta</a>
    <p class="mx-4 font-light">Completa tu perfil y ayudanos a conocerte mejor :)</p>

<!--    <div class="space-y-4 flex justify-center bg-cover items-center shadow-md w-32 h-32 rounded-full bg-gray-300 overflow-hidden">-->
<!--      <a-picture :src="picture" class="w-32 h-32 object-cover" />-->
<!--    </div>-->
    <p class="">Por favor, rellena tus datos de contacto</p>

    <div class="space-y-4 flex flex-col w-full px-4 text-black text-onboarding_light_gray">
      <input
        type="text"
        placeholder="Nombre del restaurante *"
        :value="name"
        @input="$emit('update:name', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full">

<!--      <input-->
<!--        type="text"-->
<!--        placeholder="Perfil de instagram (opcional)"-->
<!--        :value="ig_username"-->
<!--        @input="$emit('update:ig_username', $event.target.value)"-->
<!--        class="border rounded-md px-4 py-2 w-full">-->
      <div class="text-left">
        <div
          class="border overflow-hidden rounded-md w-full flex justify-between items-center px-2 bg-white"
          :class="{
            'border-red-500': igUsed === true || igError,
            'border-green-600': igUsed === false,
            'border-gray': igUsed === null
          }">
          <label for="igUsername" class="text-onboarding_light_gray flex-none text-lg font-bold py-3 items-center">@</label>
          <input
            id="igUsername"
            type="text"
            placeholder="Usuario de Instagram *"
            v-model="igUsername"
            @input="updateIg($event)"
            v-debounce:500="search => igVerify(search)"
            class="unstyled flex-grow w-full py-3 focus:outline-none">
<!--          v-debounce:1000="search => igVerify(search)"-->
          <a-loader class="w-10 h-6" color="text-gray-400" v-if="igVerifying" />
          <template v-else-if="igUsed === true">
            <i class="icon-close border p-1 text-sm border-red-500 rounded-full text-red-500" />
          </template>
          <template v-else-if="igUsed === false">
            <i class="icon-checkCircle text-green-500 text-xl mr-1" />
          </template>
        </div>
        <small class="text-red-500" v-if="igUsed === true">
          {{ ig_username }} ya está en uso
        </small>
        <small class="text-green-500" v-if="igUsed === false">
          {{ ig_username }} está disponible
        </small>
        <small class="text-red-500" v-if="igError === true">
          Ha ocurrido un error
        </small>
      </div>

<!--      <input-->
<!--        type="text"-->
<!--        placeholder="CIF *"-->
<!--        :value="cif"-->
<!--        @input="$emit('update:cif', $event.target.value)"-->
<!--        class="border rounded-md px-4 py-2 w-full">-->

      <input
        type="text"
        :value="contact_name"
        placeholder="Nombre de la persona de contacto *"
        @input="$emit('update:contact_name', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full">

<!--      <input-->
<!--        type="tel"-->
<!--        :value="phone"-->
<!--        placeholder="Teléfono para reserva *"-->
<!--        @input="$emit('update:phone', $event.target.value)"-->
<!--        class="border rounded-md px-4 py-2 w-full"-->
<!--        maxlength="9"-->
<!--        pattern="[0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{2}">-->
      <div class="overflow-hidden rounded-md w-full flex justify-between items-center px-2 bg-white">
        <label for="phone" class="text-onboarding_light_gray flex-none font-bold py-3 items-center">+34</label>
        <Mask
        id="phone"
        v-model="newPhone"
        class="border-none rounded-md px-4 py-2 flex-grow"
        placeholder="Teléfono para reserva *"
        mask="999999999"/>
      </div>
<!--      <input-->
<!--        type="text"-->
<!--        :disabled="invitation_code_verify"-->
<!--        :value="invitation_code"-->
<!--        placeholder="Código de invitacion (opcional)"-->
<!--        @input="$emit('update:invitation_code', $event.target.value)"-->
<!--        class="border rounded-md px-4 py-2 w-full">-->

      <div class="text-left">
        <div
            class="border overflow-hidden rounded-md w-full flex justify-between items-center px-2 bg-white"
            :class="{
          'border-red-500': codeConsumed === true || codeError,
          'border-green-600': codeConsumed === false,
          'border-gray': codeConsumed === null
        }">
          <input
              type="text"
              :disabled="invitation_code_verify"
              placeholder="Código de invitación (opcional)"
              :value="invitation_code"
              @input="$emit('update:invitation_code', $event.target.value)"
              v-debounce:500="search => decode(search)"
              class="unstyled flex-grow w-full px-2 -ml-2 py-3 focus:outline-none">
          <a-loader class="w-10 h-6" color="text-gray-400" v-if="decoding" />
          <template v-else-if="codeConsumed === true">
            <i class="icon-close border p-1 text-sm border-red-500 rounded-full text-red-500" />
          </template>
          <template v-else-if="codeConsumed === false">
            <i class="icon-checkCircle text-green-500 text-xl mr-1" />
          </template>
        </div>
        <small class="text-red-500" v-if="codeConsumed === true">
          {{ invitation_code }} no está disponible
        </small>
        <small class="text-green-500" v-if="codeConsumed === false">
          {{ invitation_code }} está disponible
        </small>
        <small class="text-red-500" v-if="codeError === true">
          Error, {{ codeErrorMessage }}
        </small>
      </div>

      <div v-if="confirmation" class="text-red text-left">
        Por favor complete todos los campos
      </div>
    </div>
  </div>
</template>

<script>
// TODO Completar la regex del ig username
import Mask from 'primevue/inputmask'
import Logo from '@/components/logos/logo-white'

export default {
  components: {
    Mask,
    Logo
  },
  props: {
    name: { required: false, type: String, default: '' },
    ig_username: { required: false, type: String, default: '' },
    cif: { required: false, type: String, default: '' },
    contact_name: { required: false, type: String, default: '' },
    phone: { required: false, type: String, default: '' },
    picture: { required: false, type: String, default: '' },
    confirmation: { required: false, type: Boolean, default: false },
    invitation_code: { required: false, type: String, default: '' },
    invitation_code_verify: { required: false, type: Boolean, default: false }
  },
  emits: ['should-disable-next-code', 'should-disable-next-igusername', 'update:name', 'update:ig_username', 'update:cif', 'update:contact_name', 'update:phone', 'update:invitation_code'],
  data () {
    return {
      validation: false,
      codeConsumed: null,
      codeError: null,
      codeErrorMessage: '',
      decoding: false,
      igUsed: null,
      igVerifying: null,
      igError: null,
      newPhone: '',
      igUsername: ''
    }
  },
  watch: {
    newPhone: function (value) {
      const aux = value.replace(/\D/g, '')
      this.$emit('update:phone', aux)
    },
    phone: function (value) {
      if (value.length < 9) {
        this.$emit('should-disable-next-code', true)
      } else {
        this.$emit('should-disable-next-code', false)
      }
    }
    // ig_username: function (value) {
    //   const oldValue = this.ig_username
    //   const newValue = value
    //   console.log(value)
    //   if (newValue.search('@') !== -1) {
    //     console.log('old: ' + oldValue)
    //     this.$emit('update:ig_username', oldValue)
    //   } else {
    //     console.log('new: ' + newValue)
    //     this.$emit('update:ig_username', newValue)
    //   }
    // }
  },
  computed: {
    igFilter () {
      return this.ig_username
    }
  },
  methods: {
    updateIg (event) {
      const oldValue = this.ig_username
      const newValue = event.target.value
      // const regex = new RegExp(/@|%|#|&|\*|\$\^/)
      const regex = new RegExp(/[\x20-\x2B]|\x2C|\x2F|[\x3A-\x40]|[\x5B-\x5E]|\x60|[\x7B-\x7E]/)
      const test = regex.test(newValue)
      // console.log(newValue)
      if (test) {
        this.$emit('update:ig_username', oldValue)
        this.igUsername = oldValue
      } else {
        this.$emit('update:ig_username', newValue)
        this.igUsername = newValue
      }
    },
    phoneFormatter (phone) {
      const aux = phone.replace(/\D/g, '')
      this.$emit('update:phone', aux)
    },
    decode (code) {
      this.decoding = !this.decoding
      this.$repository.invitations
        .decode(code)
        .then(({ data }) => {
          this.codeConsumed = data.status === 'consumed'
          this.$emit('should-disable-next-code', false)
          this.codeError = false
        })
        .catch((err) => {
          this.codeConsumed = null
          console.log(err.response.data.error_code)
          if (!this.invitation_code) {
            this.codeError = null
            this.$emit('should-disable-next-code', false)
          } else {
            this.codeError = true
            this.$emit('should-disable-next-code', true)
          }
          switch (err.response.data.error_code) {
            case 'EI001': {
              this.codeErrorMessage = 'el código debe tener minimo 16 caracteres'
              break
            }
            case 'EI002': {
              this.codeErrorMessage = 'el código introducido es incorrecto'
            }
          }
        })
        .finally(() => (this.decoding = !this.decoding))
    },
    igVerify (igUsername) {
      if (igUsername.length > 2) {
        this.igVerifying = !this.igVerifying
        this.$repository.session
          .igUsername({ ig_username: igUsername })
          .then(({ data }) => {
            this.igUsed = data._exists
            if (data._exists) {
              this.$emit('should-disable-next-igusername', true)
            } else {
              this.$emit('should-disable-next-igusername', false)
            }
            this.igError = false
          })
          .catch((error) => {
            if (error.response?.status === 422) {
              this.$emit('should-disable-next-igusername', false)
            } else {
              this.igError = true
              this.$emit('should-disable-next-igusername', true)
            }
            this.igUsed = null
          })
          .finally(() => (this.igVerifying = !this.igVerifying))
      } else {
        this.igUsed = null
      }
    }
  },
  mounted () {
    this.igUsername = this.ig_username
    this.newPhone = this.phone
    if (this.invitation_code) this.decode(this.invitation_code)
  }
}
</script>

<style scoped>

#phone{
  height: 48px !important;
  border: none;
  border-radius: 0 !important;
  padding: 0 2px;
  line-height: 48px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: left !important;
  font-size: 14px !important;
}
#phone:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-inputtext{
  background-color: #fff !important;
}
</style>
