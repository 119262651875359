<template>
  <div class="flex flex-col items-center justify-center px-4">
    <div class="h-20 w-20 rounded-full mb-2 bg-red-300" />
    <p class="text-xl">{{ facebookUser?.name }}</p>
    <small class="text-gray-300 text-base">{{ facebookUser?.email }}</small>
    <p class="mt-4 font-light text-lg px-16">Todo listo! ya estas a bordo, hora de explorar OnlyFoodies</p>
  </div>
</template>

<script>
export default {
  props: {
    facebookUser: { required: false, type: Object, default: () => ({}) }
  }
}
</script>
