<template>
  <a-stepper :slots="9" class="h-screen flex flex-col overflow-auto stepper-padding">

    <template #step-1>
      <Step1/>
    </template>

    <template #step-2>
      <Step2/>
    </template>

    <template #step-3="{ goNext }">
      <!--        <transition name="fade">-->
      <Step3 @start="goNext"/>
      <!--        </transition>-->
    </template>

    <template #step-4>
      <Step6
        v-model:email="user.email"
        v-model:password="user.password"
        v-model:password_confirm="user.password_confirm"
        v-model:validEmail="validEmail"
        v-model:terms="user.terms"
        v-model:notifications="user.notifications"
        v-model:emailError="emailError"
        :confirmation="confirmation"
        class="flex-grow flex"/>
    </template>

    <template #step-5>
      <Step4
        v-model:name="user.name"
        v-model:ig_username="user.ig_username"
        v-model:cif="user.cif"
        v-model:contact_name="user.contact_name"
        v-model:phone="user.phone"
        v-model:invitation_code="user.invitation_code"
        :confirmation="confirmation_step4"
        :picture="user.picture"
        :invitation_code_verify="invitation_code_verify"
        @should-disable-next-code="shouldDisableNextCode = $event"
        @should-disable-next-igusername="shouldDisableNextIgUsername = $event"
        class="flex-grow flex"/>
    </template>

    <template #step-6>
      <Step5
        v-model:address="user.address"
        v-model:city="user.city"
        v-model:state="user.state"
        v-model:zip_code="user.zip_code"
        :confirmation="confirmation"
        class="flex-grow flex"/>
    </template>

    <template #step-7>
      <Step7
        v-model:cif="user.cif"
        v-model:business_name="user.billing_address.business_name"
        v-model:billing_cif="user.billing_address.cif"
        v-model:billing_address="user.billing_address.address_line"
        v-model:billing_city="user.billing_address.city"
        v-model:billing_state="user.billing_address.state"
        v-model:billing_zip_code="user.billing_address.zip_code"
        v-model:billing_country="user.billing_address.country"
        v-model:same_address="sameAddress"
        :address="{ address: user.address, city: user.city, state: user.state, zip_code: user.zip_code }"
        :confirmation="confirmation"
        class="flex-grow flex"/>
    </template>
    <template #step-8>
    </template>

    <template #footer="{ index, goNext, goPrev }">
      <div v-if=" index >= 4">
        <button
          @click="goPrev"
          v-if="index >= 5"
          class="bg-white absolute bottom-4 left-4 focus:outline-none flex justify-center text-black p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"
        >
          <button class="rounded-full h-10 w-10">
            <i class="icon-arrowLeft"></i>
          </button>
        </button>

        <button
          :disabled="disableNext(index)"
          @click="register(goNext, index)"
          class="bg-white absolute bottom-4 right-4 focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"
          :class="index === 9 ? 'w-full' : '' "
        >
          <a-loader v-if="loadings.facebook || loadings.store || loadings.email || confirmation" color="text-black"
                    class="h-10 w-10"/>
          <button v-else-if="index !== 9" class="rounded-full h-10 w-10 text-black">
            <i class="icon-arrowRight"></i>
          </button>
        </button>
      </div>
      <div class="absolute bottom-12 flex justify-between p-4 items-center w-full" v-else>
        <button
          :disabled="index === 1"
          @click="goPrev"
          v-if="index < 4"
          class="bg-white focus:outline-none flex text-black justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray"
        >
          <button class="rounded-full h-10 w-10">
            <i class="icon-arrowLeft"></i>
          </button>
        </button>

        <span class="flex space-x-1">
            <span v-for="n in 3" :key="n" :class="['w-3 inline-block transition transition-all ease-in-out duration-300 h-3 rounded-full', {
              'w-6 bg-SFRed': index === n,
              'w-3 bg-onboarding_light_gray': index !== n
            }]"/>
          </span>
        <!--        <button class="bg-primary focus:outline-none flex justify-center text-white p-2 rounded-full uppercase disabled:bg-gray" @click="goNext" v-if="index < 3">-->
        <!--          <i class="rounded-full h-10 w-10 text-white bg-primary icon-arrowRight"></i>-->
        <!--        </button>-->
        <button
          @click="goNext"
          v-if="index <= 2"
          :disabled="index >= 3"
          class="bg-SFRed focus:outline-none flex justify-center text-white p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"
        >
          <button class="rounded-full h-10 w-10">
            <i class="icon-arrowRight"></i>
          </button>
        </button>
        <div v-else class="w-14"></div>
      </div>

<!--      <div class="fixed inset-x-0 container mx-auto bottom-0 max-w-5xl hidden">-->
<!--        <div class="flex justify-between py-4 px-8" v-if="index >= 4">-->
<!--          <button-->
<!--            @click="goPrev"-->
<!--            v-if="index >= 6"-->
<!--            class="bg-white focus:outline-none flex justify-center text-black p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"-->
<!--          >-->
<!--            <button class="rounded-full h-10 w-10">-->
<!--              <i class="icon-arrowLeft"></i>-->
<!--            </button>-->
<!--          </button>-->
<!--          <div v-else class="w-14" />-->

<!--          <button-->
<!--            :disabled="disableNext(index)"-->
<!--            @click="register(goNext, index)"-->
<!--            class="bg-white focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"-->
<!--            :class="index === 9 ? 'w-full' : '' "-->
<!--          >-->
<!--            <a-loader v-if="loadings.facebook || loadings.store || loadings.email || confirmation" color="text-black"-->
<!--                      class="h-10 w-10"/>-->
<!--            <button v-else-if="index !== 9" class="rounded-full h-10 w-10 text-black">-->
<!--              <i class="icon-arrowRight"></i>-->
<!--            </button>-->
<!--            <template v-else>-->
<!--              SALTAR ESTE PASO-->
<!--            </template>-->
<!--          </button>-->
<!--          &lt;!&ndash;        <div class="flex justify-end p-4">-->
<!--                  <button v-if="index === 7 " class="rounded-full bg-primary h-12 text-white w-full" @click="register(goNext, index)">-->
<!--                    FINALIZAR-->
<!--                  </button>-->
<!--                  <button v-else-if="index !== 3" class="rounded-full bg-primary h-12 w-12 text-white" @click="register(goNext, index)">-->
<!--                    <i class="icon-arrowRight"></i>-->
<!--                  </button>-->
<!--                </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="flex justify-between py-4 px-8 items-center" v-else>-->
<!--          <button-->
<!--            :disabled="index === 1"-->
<!--            @click="goPrev"-->
<!--            v-if="index < 4"-->
<!--            class="bg-white focus:outline-none flex text-black justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray"-->
<!--          >-->
<!--            <button class="rounded-full h-10 w-10">-->
<!--              <i class="icon-arrowLeft"></i>-->
<!--            </button>-->
<!--          </button>-->

<!--          <span class="flex space-x-1">-->
<!--            <span v-for="n in 3" :key="n" :class="['w-3 inline-block transition transition-all ease-in-out duration-300 h-3 rounded-full', {-->
<!--              'w-6 bg-SFRed': index === n,-->
<!--              'w-3 bg-onboarding_light_gray': index !== n-->
<!--            }]"/>-->
<!--          </span>-->
<!--          &lt;!&ndash;        <button class="bg-primary focus:outline-none flex justify-center text-white p-2 rounded-full uppercase disabled:bg-gray" @click="goNext" v-if="index < 3">&ndash;&gt;-->
<!--          &lt;!&ndash;          <i class="rounded-full h-10 w-10 text-white bg-primary icon-arrowRight"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;        </button>&ndash;&gt;-->
<!--          <button-->
<!--            @click="goNext"-->
<!--            v-if="index <= 2"-->
<!--            :disabled="index >= 3"-->
<!--            class="bg-SFRed focus:outline-none flex justify-center text-white p-2 rounded-full uppercase transition-all ease-in-out duration-500 disabled:bg-onboarding_light_gray disabled:text-black"-->
<!--          >-->
<!--            <button class="rounded-full h-10 w-10">-->
<!--              <i class="icon-arrowRight"></i>-->
<!--            </button>-->
<!--          </button>-->
<!--          <div v-else class="w-14"></div>-->
<!--        </div>-->
<!--      </div>-->
    </template>
  </a-stepper>
</template>

<script>
import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'
import Step4 from './step-4'
import Step5 from './step-5'
import Step6 from './step-6'
import Step7 from './step-8'

import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7
  },
  data: () => ({
    user: {
      name: '',
      ig_username: '',
      contact_name: '',
      phone: '',
      email: '',
      password: '',
      password_confirm: '',
      address: '',
      city: '',
      state: 'madrid',
      zip_code: '',
      terms: false,
      notifications: false,
      invitation_code: '',
      cif: '',
      billing_address: {
        cif: '',
        address_line: '',
        city: '',
        state: '',
        zip_code: '',
        business_name: '',
        country: ''
      }
    },
    loadings: {
      facebook: false,
      store: false,
      email: false
    },
    body: {
      name: '',
      ig_username: '',
      contact_name: '',
      phone: '',
      latitude: '',
      longitude: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country_iso: 'ES',
      email: '',
      password: '',
      privacy_agreement: {
        terms_and_privacy: false,
        commercial_communications: false
      },
      cif: '',
      invitation_code: ''
    },
    credentials: {
      body: []
    },
    sameAddress: false,
    validEmail: false,
    confirmation: false,
    confirmation_step4: false,
    nextStep: false,
    registered: null,
    invitation_code_verify: false,
    shouldDisableNextCode: false,
    shouldDisableNextIgUsername: false,
    emailError: false
  }),
  computed: {
    ...mapState({
      facebookSession: ({ session }) => session.temporalFacebookLogin,
      localUser: ({ session }) => session.user
    })
  },
  methods: {
    ...mapActions({
      store: 'restaurants/store',
      login: 'session/login',
      emailConfirm: 'session/emailConfirm',
      setNewUser: 'session/setNewUser'
    }),
    disableNext (index) {
      return this.loadings.store ||
        this.user.terms === false ||
        (this.user.ig_username.length < 3 && index === 5) ||
        this.shouldDisableNextCode ||
        this.shouldDisableNextIgUsername ||
        this.user.password.length < 6 || this.user.password_confirm !== this.user.password ||
        ((this.user.phone.length < 9 || !this.user.name) && index === 5) ||
        ((!this.user.address || !this.user.city || !this.user.state || !this.user.zip_code) && index === 6) ||
        ((!this.user.billing_address.address_line || !this.user.billing_address.city || !this.user.billing_address.zip_code || !this.user.cif || !this.user.billing_address.business_name || this.user.cif.length < 9) && index === 7)
    },
    register (goNext, index) {
      // const { accessToken, userID, api_user_type: apiUserType } = this.facebookSession
      switch (index) {
        case 4: {
          this.loadings.email = !this.loadings.email
          this.validEmail = false
          this.confirmation = false
          if (this.user.email && this.user.password && this.user.password_confirm && this.user.terms === true) {
            this.$repository.session.emailConfirm({ email: this.user.email })
              .then(response => {
                if (response.data._exists) {
                  this.validEmail = true
                  this.confirmation = true
                } else {
                  this.validEmail = false
                  this.confirmation = false
                  goNext()
                }
              })
              .catch(() => (this.emailError = true))
              .finally(() => {
                this.loadings.email = !this.loadings.email
                this.confirmation = false
              })
            // this.confirmation = false
          } else {
            this.confirmation = false
            this.loadings.email = !this.loadings.email
          }
          break
        }
        case 5: {
          this.confirmation = false

          if (this.user.name && this.user.contact_name && this.user.phone) {
            goNext()
          } else {
            this.confirmation_step4 = true
          }
          break
        }
        case 6: {
          this.confirmation = false

          if (!this.user.address) {
            this.confirmation = true
          } else {
            this.confirmation = false
            goNext()
          }

          break
        }
        case 7: {
          this.confirmation = false
          if (this.user.address) {
            this.body = {
              ...this.user,
              phone: '+34' + this.user.phone,
              privacy_agreement: {
                terms_and_privacy: this.user.terms,
                commercial_communications: this.user.notifications
              }
            }
            this.loadings.store = !this.loadings.store
            this.$repository.restaurants
              .store({ ...this.body })
              .then(({ data }) => {
                this.registered = data
                this.setNewUser(data)
                // this.setUser.commit('SET_USER', data)
              })
              .catch((error) => (console.log(error)))
              .finally(() => {
                this.loadings.store = !this.loadings.store
                this.$router.replace({ name: 'Explore' })

                // this.credentials.body = {
                //   email: this.body.email,
                //   password: this.body.password
                // }
                // this.login(this.credentials.body)
                //   .catch(error => (console.log(error)))
                //   .finally(() => {
                //     this.loadings.store = !this.loadings.store
                //     this.$router.replace({ name: 'Explore' })
                //   })
              })
          } else {
            this.confirmation = true
          }
          break
        }
        default: {
          goNext()
          break
        }
      }
    },
    redirectHome () {
      this.$router.push({ path: '/login' })
    }
  },
  mounted () {
    window.scrollTo(0, 100)
    if (this.$route.query.invitation_code) {
      this.user.invitation_code = this.$route.query.invitation_code
      this.invitation_code_verify = true
    } else {
      this.invitation_code_verify = false
    }
  }
}
</script>
<!--<style scoped>-->
<!--.stepper-padding{-->
<!--  padding-top: 64px;-->
<!--  padding-bottom: 88px;-->
<!--}-->
<!--</style>-->
