<template>
  <div class="flex flex-col space-y-6 px-4 mb-20">
    <Logo class="text-center mt-6 w-64 cursor-pointer" @click="$router.go(-1)"/>
<!--    <a href="/login" class="text-primary mt-6">Ya tengo cuenta</a>-->
<!--    <p class="mx-24 font-light text-center">Agrega tu correo electrónico y una contraseña para entrar en SoloFoodies</p>-->

    <div class="flex justify-center items-center">
      <i class="icon-lock text-2xl text-onboarding_light_gray"></i>
    </div>

    <div class="space-y-4 flex flex-col w-full">
<!--      <input-->
<!--        :disabled="invitation_code_verify"-->
<!--        type="text"-->
<!--        placeholder="Código de invitación"-->
<!--        :value="invitation_code"-->
<!--        @input="$emit('update:invitation_code', $event.target.value)"-->
<!--        v-debounce:500="search => decode(search)"-->
<!--        class="border rounded-md px-4 py-2 w-full disabled:bg-gray-100 focus:outline-none">-->
      <div class="text-left">
        <div
            class="border overflow-hidden rounded-md w-full flex justify-between items-center px-2 bg-white"
            :class="{
          'border-red-500': codeConsumed === true || codeError,
          'border-green-600': codeConsumed === false,
          'border-gray': codeConsumed === null
        }">
          <input
              type="text"
              :disabled="invitation_code_verify"
              placeholder="Código de invitación *"
              :value="invitation_code"
              @input="$emit('update:invitation_code', $event.target.value)"
              @focus="$emit('update:invitation_code', $event.target.value)"
              v-debounce:500="search => decode(search)"
              class="unstyled flex-grow w-full px-2 -ml-2 py-3 focus:outline-none">
          <a-loader class="w-10 h-6 bg-white" color="text-onboarding_light_gray" v-if="decoding" />
          <template v-else-if="codeConsumed === true">
            <i class="icon-close border p-1 text-sm border-red-500 rounded-full text-red-500" />
          </template>
          <template v-else-if="codeConsumed === false">
            <i class="icon-checkCircle text-green-500 text-xl mr-1" />
          </template>
        </div>
        <small class="text-red-500" v-if="codeConsumed === true">
          {{ invitation_code }} no está disponible
        </small>
        <small class="text-green-500" v-if="codeConsumed === false">
          {{ invitation_code }} está disponible
        </small>
        <small class="text-red-500" v-if="codeError === true">
          Error, el código introducido es incorrecto. Debe tener 16 caracteres.
        </small>
      </div>

      <input
        type="email"
        :value="email"
        placeholder="Correo electrónico *"
        @input="$emit('update:email', $event.target.value); $emit('update:emailError', false)"
        class="rounded-md px-4 py-2 w-full focus:outline-none"
        :class="validEmail || emailError? 'border-custom-red' : ''"
      >

      <div v-if="validEmail" class="text-red text-left">
        Este correo ya ha sido tomado
      </div>

      <div v-if="emailError" class="text-red text-left">
        El correo ingresado es incorrecto
      </div>

      <input
        type="text"
        placeholder="Nombre *"
        :value="name"
        @input="$emit('update:name', $event.target.value)"
        class="rounded-md px-4 py-2 w-full focus:outline-none">

      <div
        class="overflow-hidden rounded-md w-full flex justify-between items-center px-2 bg-white"
        :class="{
          'border-red-500': igUsernameTaken === true,
          'border-gray' : igUsernameTaken === false
        }">
        <label for="igUsername" class="text-onboarding_light_gray flex-none text-lg font-bold py-3 items-center">@</label>
        <input
          id="igUsername"
          type="text"
          placeholder="Usuario de Instagram *"
          v-model="igUsername"
          @input="updateIg($event); $emit('update:igUsernameTaken', false)"
          class="rounded-md unstyled flex-grow w-full py-3 focus:outline-none"
        >
      </div>
      <small class="text-red-500 text-left" v-if="igUsernameTaken">
        El nombre de usuario: {{ ig_username }} ya se encuentra registrado.
      </small>

      <div class="flex items-center custom-rounded-border bg-white">
        <input
          ref="password_input"
          type="password"
          placeholder="Contraseña *"
          :value="password"
          @input="$emit('update:password', $event.target.value)"
          class="border rounded-md px-4 py-2 w-full focus:outline-none input-cancel-border">
        <i @click="revealPassword" class="flex-none icon-eye px-2 text-xl text-onboarding_light_gray font-bold"></i>
      </div>

      <div v-if="password.length < 6 && password" class="text-red text-left">
        La contraseña debe tener al menos 6 caracteres
      </div>

      <div class="text-white text-sm">
        SoloFoodies recoge tus datos para gestionar el alta dentro del Servicio. Si aceptas, te
        enviaremos comunicaciones comerciales sobre nuestros servicios, novedades y
        actualizaciones, a los que siempre te podrás oponer. Puedes ejercer tus derechos de
        acceso, rectificación, supresión y oposición, entre otros, según indica nuestra Pol. de
        Privacidad.
      </div>

      <div class="text-center custom-max-w mx-auto text-white">
        <div class="space-x-2">
          <input type="checkbox" id="comunications" v-model="notificationsInput" @change="$emit('update:notifications', $event.target.checked)">
          <label for="comunications">
            Acepto que Solofoodies me envíe comunicaciones comerciales
          </label>
        </div>
      </div>

      <div class="text-center custom-max-w mx-auto text-white">
        <div class="space-x-2">
          <input type="checkbox" id="terms" v-model="termsInput" @change="$emit('update:terms', $event.target.checked)">
          <label for="terms">
            <!--            Aceptar los <router-link class="text-primary">términos y condiciones</router-link>-->
            Acepto las <a href="/terms" class="inline-block underline" target="_blank">Condiciones de Uso</a> y la <a href="/politics" class="inline-block underline" target="_blank">Pol. de Privacidad</a>
          </label>
        </div>
      </div>

<!--      <div>-->
<!--        <div class="space-x-2">-->
<!--          <input type="checkbox" id="terms" @change="$emit('update:terms', $event.target.checked)">-->
<!--          <label for="terms">-->
<!--            Aceptar los <router-link to="/terms" class="text-primary">términos y condiciones</router-link>-->
<!--          </label>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white'

export default {
  props: {
    name: { required: false, type: String, default: '' },
    email: { required: false, type: String, default: '' },
    password: { required: false, type: String, default: '' },
    invitation_code: { required: false, type: String, default: '' },
    invitation_code_verify: { required: false, type: Boolean, default: false },
    ig_username: { required: false, type: String, default: '' },
    validEmail: { required: false, type: Boolean, default: false },
    emailError: { required: false, type: Boolean, default: false },
    igUsernameTaken: { required: false, type: Boolean, default: false },
    terms: { required: false, type: Boolean, default: false },
    notifications: { required: false, type: Boolean, default: false }
  },
  emits: ['update:emailError', 'should-disable-next', 'should-disable-next-code', 'update:name', 'update:email', 'update:password', 'update:terms', 'update:notifications', 'update:invitation_code', 'update:ig_username', 'update:igUsernameTaken'],
  components: {
    Logo
  },
  data: () => ({
    emailExists: null,
    codeConsumed: null,
    codeError: null,
    searching: false,
    decoding: false,
    validation_invitation: false,
    igUsername: '',
    termsInput: false,
    notificationsInput: false
  }),
  methods: {
    updateIg (event) {
      const oldValue = this.ig_username
      const newValue = event.target.value
      // const regex = new RegExp(/@|%|#|&|\*|\$\^/)
      const regex = new RegExp(/[\x20-\x2B]|\x2C|\x2F|[\x3A-\x40]|[\x5B-\x5E]|\x60|[\x7B-\x7E]/)
      const test = regex.test(newValue)
      // console.log(newValue)
      if (test) {
        this.$emit('update:ig_username', oldValue)
        this.igUsername = oldValue
      } else {
        this.$emit('update:ig_username', newValue)
        this.igUsername = newValue
      }
    },
    searchEmail (email) {
      this.searching = !this.searching
      this.$repository.session
        .emailConfirm({ email })
        .then(({ data }) => {
          this.emailExists = data._exists
          this.$emit('should-disable-next', data._exists)
        })
        .catch(() => (this.emailExists = null))
        .finally(() => (this.searching = !this.searching))
    },
    decode (code) {
      this.decoding = !this.decoding
      this.$repository.invitations
        .decode(code)
        .then(({ data }) => {
          this.codeConsumed = data.status === 'consumed'
          this.$emit('should-disable-next-code', this.codeConsumed)
          this.codeError = false
        })
        .catch(() => {
          this.codeConsumed = null
          this.$emit('should-disable-next-code', true)
          if (!this.invitation_code) {
            this.codeError = null
          } else {
            this.codeError = true
          }
        })
        .finally(() => (this.decoding = !this.decoding))
    },
    revealPassword () {
      const password = this.$refs.password_input
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    }
  },
  mounted () {
    this.decode(this.invitation_code)
    this.igUsername = this.ig_username
    this.termsInput = this.terms
    this.notificationsInput = this.notifications
  }
}
</script>
