<template>
  <component :is="isNative ? 'native-register' : 'facebook-register'"/>
</template>

<script>
import NativeRegister from './native/index'
import FacebookRegister from './facebook/index'
export default {
  components: {
    NativeRegister,
    FacebookRegister
  },
  data: () => ({
    isNative: false
  }),
  mounted () {
    this.isNative = this.$route.name === 'register.native'
  }
}
</script>

<style scoped>

</style>
